.Comic {
  margin-bottom: 30px;

  &--2_panels {
    width: 487px;
  }

  &--3_panels {
    width: 616px;
  }
}
