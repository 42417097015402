.CodeLink {
  margin-top: 100px;
  margin-bottom: 50px;
  color: gray;
  font-size: small;

  &__link {
    color: gray;
  }
}
