.NumPanelsWrapper {
  width: 260px;

  font-size: 14px;
  text-align: right;
}

.NumPanelsSwitcher {
  display: inline-block;

  color: #000;
  text-align: right;
  text-decoration: none;
}
