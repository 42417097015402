.Header {
  height: 40px;
  width: 370px;
  background-image: url('../../images/dinosaur_remix.png');
  background-repeat: no-repeat;

  text-indent: -999px;

  margin-bottom: 40px;
}
