.AltTextEditor {
  position: absolute;
  right: 2px;
  bottom: 3px;
  display: flex;
  justify-content: right;

  background-image: url("../../images/edit_alt_text.png");
  background-repeat: no-repeat;
  background-position: 5px 41%;

  &__input {
    width: 300px;
    height: 30px;
    font-size: 14px;
    box-sizing: border-box;

    margin-right: 3px;
  }

  &__ok-button {
    margin-right: 3px;
  }
}
